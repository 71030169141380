import React, { useEffect, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import TecalisLogo from "../../../new/resources/tecalis.svg"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import LinkButton from "../UI/LinkButton/link-button"

const Header = ({ lang, translates }) => {
    const [openMenu, setOpenMenu] = useState(false)
    const [openMenuItem, setOpenMenuItem] = useState(-1)
    const [openLanguage, setOpenLanguage] = useState(false)

    const query = useStaticQuery(graphql`query {
        menu: allMarkdownRemark (filter: { frontmatter: { name: {eq: "menu"} } }) {
            edges {
                node {
                    frontmatter {
                        lang
                        product {
                            name
                        }
                        about_tecalis {
                            name
                        }
                        blog {
                            name
                            url
                        }
                        partners {
                            name
                            url
                        }
                        account {
                            name
                        }
                        request_demo {
                            name
                            url
                        }
                        solutions {
                            name
                        }
                        resources {
                            name
                        }
                        webinars {
                            url
                        }
                        white_papers {
                            url
                        }
                        case_study {
                            url
                        }
                    }
                }
            }
        }
        industries: allStrapiIndustry (filter: {show_in_menu: {eq: true}}) {
            edges {
                node {
                    name
                    title
                    tag
                    url
                    orderId
                    icon_new {
                        localFile{
                            childImageSharp {
                                gatsbyImageData(
                                    width: 32
                                    placeholder: NONE
                                    formats: [PNG]
                                    quality: 100
                                )
                            }
                        }
                    }
                    industries_category {
                        id
                        url
                    }
                    show_in_menu
                    locale
                }
            }
        }
        menuData: allStrapi2022Menu {
            edges {
                node {
                    locale
                    products_news_title
                    products_news {
                        title
                        description
                        link
                        type
                        image {
                            name
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 430
                                        placeholder: NONE
                                        formats: [WEBP]
                                        quality: 100
                                    )
                                }
                            }
                        }
                        image_new {
                            name
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 430
                                        placeholder: NONE
                                        formats: [WEBP]
                                        quality: 100
                                    )
                                }
                            }
                        }
                    }
                    products_title
                    products_items {
                        title
                        description
                        url
                        icon {
                            name
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        placeholder: NONE
                                        formats: [PNG]
                                        quality: 100
                                    )
                                }
                            }
                        }
                    }
                    case_use_title
                    case_use_list {
                        use_cases {
                            title
                            description
                            url
                        }
                    }
                    industries_title
                    industries_button {
                        text
                        url
                        blank
                    } 
                    areas_title
                    areas_button {
                        text
                        url
                        blank
                    }
                    about_us_news_title
                    about_us_news {
                        title
                        description
                        link
                        type
                        image {
                            name
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 430
                                        placeholder: NONE
                                        formats: [WEBP]
                                        quality: 100
                                    )
                                }
                            }
                        }
                        image_new {
                            name
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 430
                                        placeholder: NONE
                                        formats: [WEBP]
                                        quality: 100
                                    )
                                }
                            }
                        }
                    }
                    about_us_title
                    about_us_items {
                        title
                        description
                        url
                        icon {
                            name
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 32
                                        placeholder: NONE
                                        formats: [PNG]
                                        quality: 100
                                    )
                                }
                            }
                        }
                    }
                    blog_resources {
                        title
                        description
                        url
                        icon {
                            name
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 283
                                        placeholder: NONE
                                        formats: [WEBP]
                                        quality: 100
                                    )
                                }
                            }
                        }
                    }
                    kc_resources {
                        title
                        description
                        url
                        icon {
                            name
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 283
                                        placeholder: NONE
                                        formats: [WEBP]
                                        quality: 100
                                    )
                                }
                            }
                        }
                    }
                    featured_resources_title
                    featured_resources
                    login_button {
                        text
                        url
                        blank
                    }
                    get_started_button {
                        text
                        url
                        blank
                    }
                }
            }
        }
    }`)
    const baseUrl = lang === "en" ? "" : `/${lang}`
    const homeUrl = lang === "en" ? "/" : `/${lang}`
    const menu = query.menu.edges.filter(item => item.node.frontmatter.lang === lang)[0].node.frontmatter
    const industries = query.industries.edges.filter(item => item.node.locale === lang && (item.node.industries_category.id === 1 || item.node.industries_category.id === 4)).sort((a, b) => a.node.orderId - b.node.orderId)
    const areas = query.industries.edges.filter(item => item.node.locale === lang && (item.node.industries_category.id === 2 || item.node.industries_category.id === 3)).sort((a, b) => a.node.orderId - b.node.orderId)
    const menuData = query.menuData.edges.filter(item => item.node.locale === lang)[0].node

    const urlProducts = menuData.products_items.map(product => product.url)
    const urlsSolutions = query.industries.edges.map(industry => `${baseUrl}/${industry.node.industries_category.url}/${industry.node.url}`)
    const urlsAbout = menuData.about_us_items.map(item => item.url)

    const currentPath = typeof window !== "undefined" ? window.location.pathname : ""
    const classNameProduct = urlProducts.includes(currentPath) ? "options__option options__option--active" : "options__option"
    const classNameSolutions = urlsSolutions.includes(currentPath) ? "options__option options__option--active" : "options__option"
    const classNameAbout = urlsAbout.includes(currentPath) ? "options__option options__option--active" : "options__option"
    const classNameResources = (currentPath.includes(menuData.kc_resources.url) || currentPath.includes(menu.blog.url) || currentPath.includes(menu.webinars.url) || currentPath.includes(menu.white_papers.url) || currentPath.includes(menu.case_study.url)) ? "options__option options__option--active" : "options__option"
    const classNamePartners = currentPath === menu.partners.url ? "options__option options__option--active" : "options__option"

    if (typeof window !== "undefined") {
        let prevScrollPosition = window.pageYOffset

        window.onscroll = function() {
            let headerTop
            let currentScrollPosition = window.pageYOffset
            let headerElement = document.getElementById("header")

            if (headerElement) {
                if (prevScrollPosition > currentScrollPosition || prevScrollPosition <= headerElement.offsetHeight) {
                    headerTop = "0px"
                } else {
                    headerTop = `-${headerElement.offsetHeight}px`
                }

                if (headerTop !== headerElement.style.top) {
                    headerElement.style.top = headerTop
                }

                prevScrollPosition = currentScrollPosition
            }
        }
    }

    const getResourceUrl = (resource) => {
        let url = ""

        switch (resource.strapi_component) {
            case "menu.blog-post":
                url = `${menu.blog.url}/${resource.blog_post.url}`
                break
            case "menu.webinar":
                url = `${menu.webinars.url}/${resource.webinar.url}`
                break
            case "menu.white-paper":
                url = `${menu.white_papers.url}/${resource.white_paper.url}`
                break
            case "menu.case-study":
                url = `${menu.case_study.url}/${resource.case_study.url}`
                break
        }

        return url
    }

    const changeLang = (value) => {
        if (translates && translates[value]) {
            return navigate(translates[value])
        }
    }

    const onClickButtonMenu = (open = true) => {
        if (typeof document !== "undefined") {
            document.body.style.overflowY = open ? 'hidden' : 'auto'
        }

        setOpenMenu(open)
    }

    const selectOpenMenuItem = (index) => {
        if (openMenuItem === index) {
            setOpenMenuItem(-1)
        } else {
            setOpenMenuItem(index)
        }
    }

    const onFocusOut = () => {
        setTimeout(() => setOpenLanguage(false), 500)
    }

    const elements = {
        a: ({node, ...props}) => {
            return <span {...props}></span>
        }
    }

    useEffect(() => {
        if (!openMenu && typeof document !== "undefined") {
            document.body.style.overflowY = 'auto'
        }
    }, [openMenu]);

    return <header id="header" className="header">
        <div className="container">
            <div className="header__logo">
                <Link to={`${homeUrl}`}>
                    <img src={TecalisLogo} alt="Tecalis" title="Tecalis" />
                </Link>
            </div>
            <div className="header__menu-option">
                <ul className="header__menu-option__options hidden-md">
                    {/* Productos */}
                    <li className={classNameProduct}>
                        <span>{menu.product.name}</span>
                        <div className="options__option__menu">
                            <div className="container">
                                {/* Productos > Actualidad (en caso de ser una card) */}
                                {menuData.products_news.type === "Tarjeta" && <div className="menu__section">
                                    <div className="menu__section__title">{menuData.products_news_title}</div>
                                    <Link className="card card--text" to={menuData.products_news.link}>
                                        <b>{menuData.products_news.title}</b>
                                        <ReactMarkdown children={menuData.products_news.description} rehypePlugins={[rehypeRaw]} components={elements} />
                                    </Link>
                                </div>}

                                {/* Productos > Actualidad (en caso de ser una imagen) */}
                                {menuData.products_news.type === "Imagen" && <div className="menu__section">
                                    <Link to={menuData.products_news.link} className="menu__section__link">
                                        <GatsbyImage image={getImage(menuData.products_news.image_new.localFile)} alt="Tecalis" title="tecalis" />
                                    </Link>
                                </div>}

                                <div className="menu__section">
                                    <div className="menu__section__title">{menuData.products_title}</div>
                                    <div className="menu__section__items">
                                        {menuData.products_items.map((item, index) => (
                                            <div className="items__item" key={index}>
                                                <Link key={index} to={item.url} className="button-card">
                                                    <div className="button-card__icon">
                                                        <GatsbyImage image={getImage(item.icon.localFile)} alt={item.icon.name} />
                                                    </div>
                                                    <div className="button-card__info">
                                                        <div className="button-card__info__title">{item.title}</div>
                                                        <div className="button-card__info__subtitle">{item.description}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    {/* Soluciones */}
                    <li className={classNameSolutions}>
                        <span>{menu.solutions.name}</span>
                        <div className="options__option__menu">
                            <div className="container">
                                {/* Soluciones > Casos de uso */}
                                <div className="menu__section">
                                    <div className="menu__section__title">{menuData.case_use_title}</div>
                                    {menuData.case_use_list.use_cases.map((useCase, index) => (
                                        <Link to={`${baseUrl}/${useCase.url}`} className="card card--text" key={index}>
                                            <b>{useCase.title}</b>
                                            <ReactMarkdown children={useCase.description} rehypePlugins={[rehypeRaw]} components={elements} />
                                        </Link>
                                    ))}
                                </div>

                                {/* Soluciones > Industrias */}
                                <div className="menu__section">
                                    <div className="menu__section__title">{menuData.industries_title}</div>
                                    <div className="menu__section__items menu__section__items--industries">
                                        {industries.map((industry, index) => (
                                            <div className="items__item" key={index}>
                                                <Link to={`${baseUrl}/${industry.node.industries_category.url}/${industry.node.url}`} className="button-card">
                                                    <div className="button-card__icon">
                                                        <GatsbyImage image={getImage(industry.node.icon_new.localFile)} alt={industry.node.tag} />
                                                    </div>
                                                    <div className="button-card__info">
                                                        <div className="button-card__info__title">{industry.node.tag}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                        <div className="items__item">
                                            <Link to={`${menuData.industries_button.url}`} className="link--big">
                                                {menuData.industries_button.text}
                                                <i className="icon-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                {/* Soluciones > Areas */}
                                <div className="menu__section menu__section--no-border">
                                    <div className="menu__section__title">{menuData.areas_title}</div>
                                    <div className="menu__section__items">
                                        {areas.map((area, index) => (
                                            <div className="items__item" key={index}>
                                                <Link to={`${baseUrl}/${area.node.industries_category.url}/${area.node.url}`} className="button-card">
                                                    <div className="button-card__icon">
                                                        <GatsbyImage image={getImage(area.node.icon_new.localFile)} alt={area.node.tag} />
                                                    </div>
                                                    <div className="button-card__info">
                                                        <div className="button-card__info__title">{area.node.tag}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                        <div className="items__item">
                                            <Link to={`${menuData.areas_button.url}`} className="link--big">
                                                {menuData.areas_button.text}
                                                <i className="icon-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    {/* Sobre Tecalis */}
                    <li className={classNameAbout}>
                        <span>{menu.about_tecalis.name}</span>
                        <div className="options__option__menu">
                            <div className="container">
                                {menuData.about_us_news.type === "Tarjeta" && <div className="menu__section">
                                    <div className="menu__section__title">{menuData.about_us_news_title}</div>
                                    <Link className="card card--text" to={menuData.about_us_news.link}>
                                        <b>{menuData.about_us_news.title}</b>
                                        <ReactMarkdown children={menuData.about_us_news.description} rehypePlugins={[rehypeRaw]} components={elements} />
                                    </Link>
                                </div>}

                                {menuData.about_us_news.type === "Imagen" && <div className="menu__section">
                                    <Link to={menuData.about_us_news.link} className="menu__section__link">
                                        <GatsbyImage image={getImage(menuData.about_us_news.image_new.localFile)} alt="Tecalis" title="tecalis" />
                                    </Link>
                                </div>}

                                <div className="menu__section">
                                    <div className="menu__section__title">{menuData.about_us_title}</div>
                                    <div className="menu__section__items menu__section__items--about-us">
                                        {menuData.about_us_items.map((item, index) => {
                                            return <div className="items__item" key={index}>
                                                <Link to={item.url} className="button-card">
                                                    <div className="button-card__icon">
                                                        <GatsbyImage alt={item.title} image={getImage(item.icon.localFile)} />
                                                    </div>
                                                    <div className="button-card__info">
                                                        <div className="button-card__info__title">{item.title}</div>
                                                        <div className="button-card__info__subtitle">{item.description}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    {/* Recursos */}
                    <li className={classNameResources}>
                        <span>{menu.resources.name}</span>
                        <div className="options__option__menu options__option__menu--resources">
                            <div className="container">
                                <div className="menu__section">
                                    <Link to={menuData.blog_resources.url} className="menu__section__link">
                                        <GatsbyImage alt={menuData.blog_resources.title} image={getImage(menuData.blog_resources.icon.localFile)} />
                                        <div className="link__text">{menuData.blog_resources.description}</div>
                                    </Link>
                                    <Link to={menuData.kc_resources.url} className="menu__section__link">
                                        <GatsbyImage alt={menuData.kc_resources.title} image={getImage(menuData.kc_resources.icon.localFile)} />
                                        <div className="link__text">{menuData.kc_resources.description}</div>
                                    </Link>
                                </div>
                                <div className="menu__section">
                                    <div className="menu__section__title">{menuData.featured_resources_title}</div>
                                    {menuData.featured_resources.map((resource, index) => (
                                        <Link to={getResourceUrl(resource)} key={index}>{resource.title}</Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </li>

                    {/* Partners */}
                    <li className={classNamePartners}>
                        <Link to={menu.partners.url}>{menu.partners.name}</Link>
                    </li>
                </ul>

                <div className="header__menu-option__actions hidden-md">
                    {/* LANGUAGE */}
                    <div className={`dropdown ${openLanguage ? "dropdown--open" : ""}`} onBlur={onFocusOut}>
                        <button className="dropdown__trigger input" onClick={() => setOpenLanguage(!openLanguage)}>
                            <span>{lang === "es" ? "ES" : "EN"}</span>
                            <i className="icon-chevron-down"></i>
                        </button>
                        <div className="dropdown__menu">
                            <div className={`dropdown__menu__item ${lang === "es" ? "dropdown__menu__item--selected" : ""}`} onClick={() => changeLang("es")}>ES</div>
                            <div className={`dropdown__menu__item ${lang === "en" ? "dropdown__menu__item--selected" : ""}`} onClick={() => changeLang("en")}>EN</div>
                        </div>
                    </div>
                    {/* My account */}
                    <LinkButton button={menuData.login_button} className="link" />
                    {/* Ready */}
                    <LinkButton button={menuData.get_started_button} className="button button--sm" />
                </div>

                {/* Mobile > Button */}
                <div className="header__menu-option__actions header__menu-option__actions--mobile show-md">
                    <button className="button-icon" onClick={() => onClickButtonMenu(!openMenu)}>
                        <i className={openMenu ? "icon-close" : "icon-menu"} />
                    </button>
                </div>

                {/* Mobile > Menú */}
                <div className={`header__menu-option__menu ${openMenu ? "header__menu-option__menu--open" : ""}`}>
                    <div className="menu__items">

                        {/* Mobile > Productos */}
                        <div className={`item ${openMenuItem === 0 ? "item--active" : ""}`}>
                            <div className="item__header" onClick={() => selectOpenMenuItem(0)}>
                                <h6 className="item__header__title">{menu.product.name}</h6>
                                <i className="icon-chevron-down"></i>
                            </div>
                            <div className="item__body">
                                <div className="menu__section">
                                    <div className="menu__section__title hidden-md">{menuData.products_title}</div>
                                    <div className="menu__section__items">
                                        {menuData.products_items.map((item, index) => (
                                            <div className="items__item" key={index}>
                                                <Link to={item.url} className="button-card">
                                                    <div className="button-card__icon">
                                                        <GatsbyImage image={getImage(item.icon.localFile)} alt={item.icon.name} />
                                                    </div>
                                                    <div className="button-card__info">
                                                        <div className="button-card__info__title">{item.title}</div>
                                                        <div className="card__info__subtitle">{item.description}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {menuData.products_news.type === "Tarjeta" && <div className="menu__section">
                                    <div className="menu__section__title">{menuData.products_news_title}</div>
                                    <Link className="card card--text" to={menuData.products_news.link}>
                                        <b>{menuData.products_news.title}</b>
                                        <ReactMarkdown children={menuData.products_news.description} rehypePlugins={[rehypeRaw]} components={elements} />
                                    </Link>
                                </div>}

                                {menuData.products_news.type === "Imagen" && <div className="menu__section">
                                    <Link to={menuData.products_news.link} className="menu__section__link">
                                        <div className="link__image">
                                            <GatsbyImage image={getImage(menuData.products_news.image_new.localFile)} alt="Tecalis" title="tecalis" />
                                        </div>
                                    </Link>
                                </div>}
                            </div>
                        </div>

                        {/* Mobile > Soluciones */}
                        <div className={`item ${openMenuItem === 1 ? "item--active" : ""}`}>
                            <div className="item__header" onClick={() => selectOpenMenuItem(1)}>
                                <h6 className="item__header__title">{menu.solutions.name}</h6>
                                <i className="icon-chevron-down"></i>
                            </div>
                            <div className="item__body">
                                <div className="menu__section">
                                    <div className="menu__section__title">{menuData.case_use_title}</div>
                                    <div className="menu__section__card-container">
                                        {menuData.case_use_list.use_cases.map((useCase, index) => (
                                            <Link to={`${baseUrl}/${useCase.url}`} className="card card--text" key={index}>
                                                <b>{useCase.title}</b>
                                                <ReactMarkdown children={useCase.description} rehypePlugins={[rehypeRaw]} components={elements} />
                                            </Link>
                                        ))}
                                    </div>
                                </div>

                                <div className="menu__section">
                                    <div className="menu__section__title menu__section__title--pl">{menuData.industries_title}</div>
                                    <div className="menu__section__items menu__section__items--industries">
                                        {industries.map((industry, index) => (
                                            <div className="items__item" key={index}>
                                                <Link to={`${baseUrl}/${industry.node.industries_category.url}/${industry.node.url}`} className="button-card">
                                                    <div className="button-card__icon">
                                                        <GatsbyImage image={getImage(industry.node.icon_new.localFile)} alt={industry.node.tag} />
                                                    </div>
                                                    <div className="button-card__info">
                                                        <div className="button-card__info__title">{industry.node.tag}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                        <div className="items__item">
                                            <Link to={`${menuData.industries_button.url}`} className="link--big">
                                                {menuData.industries_button.text}
                                                <i className="icon-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="menu__section menu__section--no-border">
                                    <div className="menu__section__title menu__section__title--pl">{menuData.areas_title}</div>
                                    <div className="menu__section__items">
                                        {areas.map((area, index) => (
                                            <div className="items__item" key={index}>
                                                <Link to={`${baseUrl}/${area.node.industries_category.url}/${area.node.url}`} className="button-card">
                                                    <div className="button-card__icon">
                                                        <GatsbyImage image={getImage(area.node.icon_new.localFile)} alt={area.node.tag} />
                                                    </div>
                                                    <div className="button-card__info">
                                                        <div className="button-card__info__title">{area.node.tag}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                        <div className="items__item">
                                            <Link to={`${menuData.areas_button.url}`} className="link--big">
                                                {menuData.areas_button.text}
                                                <i className="icon-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mobile > Sobre Tecalis */}
                        <div className={`item ${openMenuItem === 2 ? "item--active" : ""}`}>
                            <div className="item__header" onClick={() => selectOpenMenuItem(2)}>
                                <h6 className="item__header__title">{menu.about_tecalis.name}</h6>
                                <i className="icon-chevron-down"></i>
                            </div>
                            <div className="item__body">
                                <div className="menu__section">
                                    <div className="menu__section__title hidden-md">{menuData.about_us_title}</div>
                                    <div className="menu__section__items menu__section__items--about-us">
                                        {menuData.about_us_items.map((item, index) => (
                                            <div className="items__item" key={index}>
                                                <Link to={item.url} className="button-card">
                                                    <div className="button-card__icon">
                                                        <GatsbyImage alt={item.title} image={getImage(item.icon.localFile)} />
                                                    </div>
                                                    <div className="button-card__info">
                                                        <div className="button-card__info__title">{item.title}</div>
                                                        <div className="button-card__info__subtitle">{item.description}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="menu__section">
                                    {menuData.about_us_news.type === "Tarjeta" && <div className="menu__section">
                                        <div className="menu__section__title">{menuData.about_us_news_title}</div>
                                        <Link className="card card--text" to={menuData.about_us_news.link}>
                                            <b>{menuData.about_us_news.title}</b>
                                            <ReactMarkdown children={menuData.about_us_news.description} rehypePlugins={[rehypeRaw]} components={elements}/>
                                        </Link>
                                    </div>}

                                    {menuData.about_us_news.type === "Imagen" && <div className="menu__section">
                                        <Link to={menuData.about_us_news.link} className="menu__section__link">
                                            <div className="link__image">
                                                <GatsbyImage image={getImage(menuData.about_us_news.image_new.localFile)} alt="Tecalis" title="tecalis" />
                                            </div>
                                        </Link>
                                    </div>}
                                </div>
                            </div>
                        </div>

                        {/* Mobile > Recursos */}
                        <div className={`item ${openMenuItem === 3 ? "item--active" : ""}`}>
                            <div className="item__header" onClick={() => selectOpenMenuItem(3)}>
                                <h6 className="item__header__title">{menu.resources.name}</h6>
                                <i className="icon-chevron-down"></i>
                            </div>
                            <div className="item__body">
                                <div className="menu__section menu__section--resources">
                                    <Link to={menuData.blog_resources.url} className="menu__section__link">
                                        <div className="link__image">
                                            <GatsbyImage alt={menuData.blog_resources.title} image={getImage(menuData.blog_resources.icon.localFile)} />
                                        </div>
                                        <div className="link__text">{menuData.blog_resources.description}</div>
                                    </Link>
                                    <Link to={menuData.kc_resources.url} className="menu__section__link">
                                        <div className="link__image">
                                            <GatsbyImage alt={menuData.kc_resources.title} image={getImage(menuData.kc_resources.icon.localFile)} />
                                        </div>
                                        <div className="link__text">{menuData.kc_resources.description}</div>
                                    </Link>
                                </div>
                                <div className="menu__section">
                                    <div className="menu__section__title">{menuData.featured_resources_title}</div>
                                    <div className="menu__section__links">
                                        {menuData.featured_resources.map((resource, index) => (
                                            <Link to={getResourceUrl(resource)} key={index}>{resource.title}</Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mobile > Partners */}
                        <div className="item">
                            <Link to={menu.partners.url} className="item__header">
                                <h6 className="item__header__title">{menu.partners.name}</h6>
                            </Link>
                        </div>

                        {/* Mobile > Solicita una demo */}
                        <div className="item">
                            <Link to={menu.request_demo.url} className="item__header">
                                <h6 className="item__header__title">
                                    {menu.request_demo.name}
                                </h6>
                            </Link>
                        </div>
                    </div>

                    <div className="menu__actions">
                        {/* Mobile > Idioma */}
                        <div className={`dropdown dropdown--filled ${openLanguage ? "dropdown--open" : ""}`} onBlur={onFocusOut}>
                            <button className="dropdown__trigger input" onClick={() => setOpenLanguage(!openLanguage)}>
                                <span>{lang === "es" ? "Español" : "English"}</span>
                                <i className="icon-chevron-down"></i>
                            </button>
                            <div className="dropdown__menu">
                                <div className={`dropdown__menu__item ${lang === "es" ? "dropdown__menu__item--selected" : ""}`} onClick={() => changeLang("es")}>Español</div>
                                <div className={`dropdown__menu__item ${lang === "en" ? "dropdown__menu__item--selected" : ""}`} onClick={() => changeLang("en")}>English</div>
                            </div>
                        </div>

                        <div className="menu__actions__buttons">
                            {/* Mobile > My account */}
                            <LinkButton button={menuData.login_button} className="button button--secondary button--xl" />
                            {/* Ready */}
                            <LinkButton button={menuData.get_started_button} className="button button--xl" />
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </header>
}

export default Header
